import { useReducer, useEffect, createContext, useContext, ReactNode, useMemo } from 'react';
import { Colors, UpdateColorFunction } from '../types/colors';

const defaultColors: Colors = {
  primary: '#FF0000',
  secondary: '#F19B3C',
  background: '#ffffff',
};

const SET_COLORS = 'SET_COLORS';

type ColorAction = { type: 'SET_COLORS'; payload: Colors };

const colorReducer = (state: Colors, action: ColorAction): Colors => {
  switch (action.type) {
    case SET_COLORS:
      return action.payload;
    default:
      return state;
  }
};

const ColorsContext = createContext<{ colors: Colors; updateColor: UpdateColorFunction } | undefined>(undefined);

const useColorsProvider = (): { colors: Colors; updateColor: UpdateColorFunction } => {
  const [colors, dispatch] = useReducer(colorReducer, defaultColors);

  useEffect(() => {
    const loadColors = () => {
      if (typeof window !== 'undefined') {
        const savedColors = localStorage.getItem('colors');
        if (savedColors) {
          const parsedColors = JSON.parse(savedColors);
          dispatch({ type: SET_COLORS, payload: parsedColors });
          applyColorsToDocument(parsedColors);
        }
      }
    };

    const fetchColors = async () => {
      try {
        const response = await fetch('/api/colors');
        const data = await response.json();

        // Check if the fetched data is valid
        const validData =
          data && typeof data === 'object' && Object.keys(data).length > 0 ? data : defaultColors;

        dispatch({ type: SET_COLORS, payload: validData });
        localStorage.setItem('colors', JSON.stringify(validData));
        applyColorsToDocument(validData);
      } catch (error) {
        console.error('Failed to fetch colors:', error);
        dispatch({ type: SET_COLORS, payload: defaultColors });
        applyColorsToDocument(defaultColors);
      }
    };

    const applyColorsToDocument = (colors: Colors) => {
      document.documentElement.style.setProperty('--primary-color', colors.primary);
      document.documentElement.style.setProperty('--secondary-color', colors.secondary);
      document.documentElement.style.setProperty('--background-color', colors.background);
    };

    loadColors();
    fetchColors();

    const eventSource = new EventSource('/api/colors-sse');
    eventSource.onmessage = (event) => {
      const updatedColors = JSON.parse(event.data);
      dispatch({ type: SET_COLORS, payload: updatedColors });
      localStorage.setItem('colors', JSON.stringify(updatedColors));
      applyColorsToDocument(updatedColors);
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const updateColor: UpdateColorFunction = async (type, value) => {
    const newColors = { ...colors, [type]: value };
    dispatch({ type: SET_COLORS, payload: newColors });
    localStorage.setItem('colors', JSON.stringify(newColors));
    document.documentElement.style.setProperty(`--${type}-color`, value);

    await fetch('/api/colors', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newColors),
    });
  };

  return useMemo(() => ({ colors, updateColor }), [colors]);
};

export const useColors = (): { colors: Colors; updateColor: UpdateColorFunction } => {
  const context = useContext(ColorsContext);
  if (!context) {
    throw new Error('useColors must be used within a ColorsProvider');
  }
  return context;
};

export const ColorsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const colorsProvider = useColorsProvider();

  return (
    <ColorsContext.Provider value={colorsProvider}>
      {children}
    </ColorsContext.Provider>
  );
};
